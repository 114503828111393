import * as StyleClasses from './styles/style'
import styleConfig from './styles/style/config';

// console.log('styleConfig', styleConfig['transform-origin'], styleConfig.hasOwnProperty('transform-origin'));
class Styles {
    constructor(styles) {
        this.the_class = 'Styles';
        const proxy = new Proxy(this, {
            get: function (target, propertyName, receiver) {
                if (Reflect.has(target, propertyName)) {
                    // if (propertyName == 'abc') console.log("getting existing property", propertyName);
                    return Reflect.get(target, propertyName, receiver);
                } else if (styleConfig.hasOwnProperty(propertyName) && styleConfig[propertyName] != null) {
                    // if (propertyName == 'abc') console.log("getting new property but is allowed style ", propertyName);
                    receiver[propertyName] = null;
                    return Reflect.get(target, propertyName, receiver);
                } else {
                    // if (propertyName == 'abc') console.log("getting property ELSE ", propertyName);
                }
            },
            set: function (target, propertyName, value, receiver) {
                if (Reflect.has(target, propertyName)) {
                    // if (propertyName == 'inset' ) console.log("setting existing property", propertyName);
                    if (target[propertyName] instanceof StyleClasses.Style) {
                        if (typeof value === 'object' && value instanceof StyleClasses.Style) {
                            // if (propertyName == 'inset' ) console.log("setting existing property is object", propertyName);
                            return Reflect.set(target, propertyName, value, receiver);
                        } else {
                            // if (propertyName == 'inset' ) console.log("setting existing property is value", propertyName);
                            return Reflect.set(target[propertyName], 'value', value);
                        }
                    }
                    return Reflect.set(target, propertyName, value, receiver);
                } else {
                    // if (propertyName == 'inset' ) console.log("setting new property", propertyName);
                    if (value instanceof StyleClasses.Style) {
                        return Reflect.set(target, propertyName, value, receiver);
                    }
                    if (styleConfig.hasOwnProperty(propertyName) && styleConfig[propertyName] != null) {
                        const newValue = Reflect.apply(target.newStyle, receiver, [propertyName,{ ...styleConfig[propertyName], value: value }]);
                        // console.log('ABOUTTOSETHTEVALUE', propertyName, value, newValue);
                        newValue.value = value;
                        // if (propertyName == 'inset' ) console.log("setting new property is allowed style", propertyName, newValue);
                        // if (propertyName.includes('transform') ) console.log("setting new property is allowed style", propertyName, {[propertyName]: newValue});
                        return proxy.addStyles({[propertyName]: newValue});
                        // return Reflect.set(target, propertyName, newValue, receiver);
                    }
                    return Reflect.set(target, propertyName, value, receiver);
                }
            }
        });
        proxy.addStyles(styles);
        return proxy;
    }
    addStyles(styles) {
        Object.entries(styles).forEach(([propertyName, value]) => {
            this[propertyName] = value;
        });
        return true;
    }
    getStyleOrCreateNew(propertyName, value) {
        if (!this[propertyName]) {
            this[propertyName] = value;
        }
        return { [propertyName]: this[propertyName] };
    }
    newStyle(propertyName, value) {
        if (typeof value !== 'object') value = { value: value };
        let className;
        if (!value.className) {
        className = propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
        className = 'Style' + className.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
        } else {
            className = value.className;
            // console.log('WITH THECLASSNAME', propertyName, className, StyleClasses[className], StyleClasses);
        }
        let ClassToUse = StyleClasses[className] ? StyleClasses[className] : StyleClasses.Style;
        // console.log('THESTYLESGETTIGNPASSEDASTHIS', propertyName,className, ClassToUse.name,value,'end');
        let instance;

        try {
            instance = new ClassToUse({ propertyName, ...value, styles: this });
        } catch (error) {
            // console.warn('Failed to create an instance of ClassToUse. Falling back to base Style class.', error);
            instance = new StyleClasses.Style({ propertyName, ...value, styles: this });
        }

        return instance;
    }

    getStyle(propertyName) {
        return this[propertyName];
    }
    toJSON() {
        return this.css;
    }
    get css() {
        // console.log('ooooooooooooooIIIIIIIIIIIIIIII',Object.keys(this));
        return Object.entries(this).reduce((acc, [key, style]) => {
            if (style && style.css) {
                acc[key] = style.css;
            }
            return acc;
        }, {});
    }

}

export default Styles;