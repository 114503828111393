import StyleWithUnit from './withUnit';

class StyleTransform extends StyleWithUnit {
    constructor(args) {
        super(args);
        this.the_class = 'StyleTransform';
        this.multiValueSeparator = /\s+/;
        this.defaultUnit = '';
        this._functionData = {
            rotate: '0deg',
            rotateX: '0deg',
            rotateY: '0deg',
            scale: '1',
            translate: '0%, 0%',
            skew: '0deg, 0deg'
        };
    }

    get value() {
        // 
        return this.packTransformFunctions()
    }
    increment(value) {
        this._value.value = this._value.value + value;
    }
    decrement(value) {
        this._value.value = this._value.value - value;
    }
    parseTransformFunctions(value) {
        let values = value.split(this.multiValueSeparator);
        // these will be transform functions, and must be parsed
        let result = {};
        values.forEach((v) => {
            let match = v.match(/\s*([a-zA-Z]+)\s*\(\s*([^)]+)\s*\)\s*/);
            if (match) {
                result[match[1]] = match[2];
            }
        });
        return result;
    }
    getLonghandPrefix() {
        return this.propertyName !== 'inset' ? this.propertyName + '-' : '';
    }
    packTransformFunctions() {
        let functions = ['rotate', 'rotateX', 'rotateY', 'scale', 'translate', 'skew'];
        functions = functions.map((f) => {
            const style = this.styles[`${this.getLonghandPrefix()}${f}`];
            // console.log('StyleTransform packTransformFunctions', f, `${this.getLonghandPrefix()}${f}`,`${f}(${style?.css})`,style);
            if (!style || style?.isEmpty) {
                return null;
            }
            return `${f}(${style?.css})`;
        }).filter(Boolean);
        return functions.join(' ');
        const top = this.styles[`${this.getLonghandPrefix()}top`].css;
        const right = this.styles[`${this.getLonghandPrefix()}right`].css;
        const bottom = this.styles[`${this.getLonghandPrefix()}bottom`].css;
        const left = this.styles[`${this.getLonghandPrefix()}left`].css;

        if (top === right && right === bottom && bottom === left) {
            // All four sides are the same.
            return top;
        } else if (top === bottom && right === left) {
            // Top and bottom are the same, right and left are the same.
            return `${top} ${right}`;
        } else if (right === left) {
            // Top, bottom, and sides are distinct.
            return `${top} ${right} ${bottom}`;
        } else {
            // All four sides are distinct.
            return `${top} ${right} ${bottom} ${left}`;
        }
    }
    setUnderlying(properties){
        for (const subKey in properties) {
            // console.log('YYYYYYYYYYYYYYY setting new style', this.styles[`${this.getLonghandPrefix()}${subKey}`].constructor.name,{default: this.styles[`${this.getLonghandPrefix()}${subKey}`].getDefaultValue()}, this.styles[`${this.getLonghandPrefix()}${subKey}`], `${this.getLonghandPrefix()}${subKey}`, properties[subKey]);
            this.styles[`${this.getLonghandPrefix()}${subKey}`] = properties[subKey];
        }
    }
    set value(inputValue) {
        if (this._value === undefined) {
            this._value = {};
        }
        // verbose detailed console log
        // console.log('StyleWithUnitTRANSFORM value', inputValue);
        if (typeof inputValue === 'string') {
            const transformFunctionValues = this.parseTransformFunctions(inputValue);
            // console.log('StyleWithUnitTRANSFORM value box', inputValue, transformFunctionValues);
            this._value = {};
            this.setUnderlying(transformFunctionValues);
        } else if (typeof inputValue === 'object' && inputValue != null) {
            // console.log('StyleWithUnitTRANSFORM INTPUTVALUE zzzzzzzzz', inputValue);
            if (inputValue.value !== undefined) {
                this._value = inputValue.value;
                if (inputValue.unit !== undefined) {
                    this._value.unit = inputValue.unit;
                }
            }
        } else {
            this._value.value = inputValue;
            this._value.unit = null;
        }
    }
}

export default StyleTransform;