import Alpine from 'alpinejs';
import Styles from './styles';

class Visual {
    constructor({ artboard =  null, internal_id, styles, ...args }) {
        this.the_class = 'Visual';
        if (!artboard) {
            console.error('Visual must have an artboard');
            return;
        }
        this.artboard = artboard;
        this.internal_id = internal_id ?? Math.random().toString(36).substring(7);
        // debug console log verbosely
        // console.log('NEEEEEEEEEEEEW0 Visual', args);
        const defaultStyles = { width: '100%', top: 0, left: 0, bottom: 'auto', right: 'auto', transform: '', 'transform-origin': '', color: '', fill: '', 'font-weight':''};
        this.styles = { ...defaultStyles, ...styles };
        Object.assign(this, args);
        this.excludeFromJSON = ['the_class', 'artboard', 'internal_id', 'excludeFromJSON', 'boundElements', '_boundElements', 'viewBoxEndX', 'viewBoxEndY', 'eselected'];
    }
    initDisplay() {
        this.setBoundingBox();
    }
    get styles() {
        return this._styles;
    }
    set styles(styles) {
        if (this._styles instanceof Styles) {
            this._styles.addStyles(styles);
        } else {
            this._styles = new Styles(styles);
        }
    }
    changeColor(direction, amount) {
        const color = this.styles['color'];
        if (direction === 'increase') {
            color.increment(amount);
        } else {
            color.decrement(amount);
        }
    }
    changeFontWeight(direction, amount) {
        const fontWeight = this.styles['font-weight'];
        console.log('font weight change',direction,fontWeight);
        if (direction === 'increase') {
            fontWeight.increment(amount);
        } else {
            fontWeight.decrement(amount);
        }
    }
    getMovableOffset(direction) {
        const oppositeDirections = {
            'left': 'right',
            'right': 'left',
            'top': 'bottom',
            'bottom': 'top'
        };
        // console log out everythign in detailed object for debugging
        // console.log('getMovableOffset', {direction: direction,styles_direction: this.styles.getStyle(direction).toString(),oppositeDirections: oppositeDirections,movableOffset: this.styles.getStyle(direction).toString() !== 'auto' ? direction : oppositeDirections[direction]});
        const movableOffset = this.styles.getStyle(direction).toString() !== 'auto' ? direction : oppositeDirections[direction];
        return this.styles[movableOffset];
    }

    calculateAdjustedValue(direction, movableOffset, amount) {
        const [the_number, the_unit] = this.styles[movableOffset] === undefined ? [0, '%'] : (typeof this.styles[movableOffset] === 'string' ? this.styles[movableOffset].match(/-?\d*\.?\d+|\D+/g) : [this.styles[movableOffset], '%']);
        const newValue = parseFloat(the_number) + (usable_sign * amount);
        return `${newValue}${the_unit}`;
    }

    move(direction, amount) {
        const movableOffset = this.getMovableOffset(direction);
        const usable_sign = direction !== movableOffset.propertyName ? 1 : -1;
        // console.log("moVVVVVVe", {direction: direction,amount: amount,movableOffset: movableOffset,usable_sign: usable_sign});
        if (movableOffset) {
            // .increment() or .decrement() on the movableoffset ase don sign.
            if (usable_sign === 1) {
                movableOffset.increment(amount);
            } else {
                movableOffset.decrement(amount);
            }

        }
    }
    scale(direction, amount) {
        const width = this.styles['width'];
        if (width) {
            if (direction === 'increase') {
                width.increment(amount);
            } else {
                width.decrement(amount);
            }
        }
        return;
        const transformScale = this.styles['transform-scale'];
        if (transformScale) {
            if (direction === 'increase') {
                transformScale.increment(amount / 100);
            } else {
                transformScale.decrement(amount / 100);
            }
        }
    }
    addBoundElement(element) {
        // console.log('TTTTTTTaddBoundElement', element);
        if (this._boundElements === undefined) {
            this._boundElements = [];
        }
        this._boundElements.push(element);
        this.setBoundingBox();
    }
    get boundElements() {
        return this._boundElements ?? [];
    }
    set content(value) {
        this._content = value;
        Alpine.nextTick(() => {
            // console.log('TTTTTTTforCONTENTTICK', value, this.boundElements.length ?? 'none', this.boundElements);
            this.setBoundingBox();
        });
    }
    setBoundingBox() {
        // console.log('TTTTTTTforBEFORE', this.boundElements.length ?? 'none', this.boundElements, this.boundElements?.map((element) => element.querySelector("svg text")?.getBBox()));
        const largestText = this.boundElements?.map((element) => element.querySelector("svg text")?.getBBox() ?? {})?.reduce((max, bbox) => (bbox.width * bbox.height > max.width * max.height ? bbox : max), { width: 0, height: 0 });
        const viewBoxEndX = largestText?.x + largestText?.width;
        const viewBoxEndY = largestText?.y + largestText?.height;
        this.viewBoxEndX = !isNaN(viewBoxEndX) && viewBoxEndX ? viewBoxEndX : 200;
        this.viewBoxEndY = !isNaN(viewBoxEndY) && viewBoxEndY ? viewBoxEndY : 20;
        // console.log('TTTTTTTforACTUAL', { x: this.viewBoxEndX, y: this.viewBoxEndY }, this.boundElements.length ?? 'none', this.boundElements, this.viewBoxEndX, this.viewBoxEndY, this.boundElements[0]?.querySelector("svg text")?.getBBox(), this.boundElements[0]?.querySelector("svg text"));
    }
    get content() {
        return this._content;
    }
    rotate(direction, amount) {
        const transformRotate = this.styles['transform-rotate'];
        if (transformRotate) {
            if (direction === 'increase') {
                transformRotate.increment(amount);
            } else {
                transformRotate.decrement(amount);
            }
        }
        // console.log('GGGGGGGGG', this.styles['transform'].css);
    }
    snap(towards) {
        const opposite_of_towards = {
            'left': 'right',
            'right': 'left',
            'top': 'bottom',
            'bottom': 'top'
        }[towards];

        if (this.styles[towards].css === 'auto') {
            // console.log('was auto', this.styles[towards].css, this.styles[opposite_of_towards].css);
            this.styles[towards] = this.styles[opposite_of_towards].css;
        } else {
            // console.log('was not auto', this.styles[towards].css, this.styles[opposite_of_towards].css);
            this.styles[towards] = '0%';
        }
        this.styles[opposite_of_towards] = 'auto';
    }
    flip(axis) {
        const transformRotateX = this.styles['transform-rotateX'];
        const transformRotateY = this.styles['transform-rotateY'];
        if (transformRotateX && transformRotateY) {
            if (axis === 'x') {
                if (transformRotateX.value == '180') {
                    transformRotateX.value = '0';
                } else {
                    transformRotateX.value = '180';
                }
            } else {
                if (transformRotateY.value == '180') {
                    transformRotateY.value = '0';
                } else {
                    transformRotateY.value = '180';
                }
            }
        }
        // console.log('GGGGGGGGG', this.styles['transform'].css);
    }

    //anchor uses transform-origin
    anchor(direction, amount) {
        const transformOrigin = this.styles['transform-origin'];
        // console.log('TTTTTTTanchor', { direction, amount, transformOrigin });
        if (transformOrigin) {
            if (direction === 'increase') {
                transformOrigin.increment(amount);
            } else {
                transformOrigin.decrement(amount);
            }
        }
        // console.log('GGGGGGGGG', this.styles['transform'].css);
    }

    // ...
    toJSON() {
        // returns also getter props instead of their underlying "_" props
        const descriptors = Object.getOwnPropertyDescriptors(Object.getPrototypeOf(this));
        const getterProps = Object.keys(descriptors).reduce((acc, key) => {
            if (typeof descriptors[key].get === 'function' && !this.excludeFromJSON.includes(key)) {
                acc[key] = this[key];
            }
            return acc;
        }, {});
        // console.log('ZZZZZZZZZZZZZZZZZZZZZZ88', getterProps, Object.entries(this));
        return {
            ...getterProps,
            ...Object.fromEntries(Object.entries(this).filter(([key]) => !this.excludeFromJSON.includes(key) && (!key.startsWith('_') || !(key.slice(1) in getterProps)))),
            // ...this,
        };
    }

    updateVisualID(the_id = '') {
        if (!the_id) {
            the_id = this.type === 'text' ? this.content : this.icon;
        }
        the_id = the_id?.replace(/[^a-z0-9\-_\s]+/gi, '').replace(/\s+/, ' ').split(' ').reduce((acc, word) => (acc.length <= 20) ? acc + (acc.length ? '_' : '') + word : acc, '').replace(/^[0-9_]+/, '').toLowerCase();
        if (!the_id) {
            the_id = 'rand_' + Math.round(Math.random() * 1000000000);
        }

        let i = 1;
        while (this.artboard.visuals.find(v => (v !== this && v.id === the_id))) {
            if (the_id.match(/_\d+$/gi)) {
                the_id = the_id.replace(/_\d+$/gi, '') + '_' + i;
            } else {
                the_id = the_id + '_' + i;
            }
            i++;
        }
        this.id = the_id;
    }

}

export default Visual;