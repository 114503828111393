class Style {
    constructor({ styles, value, propertyName, ...args }) {
        this.the_class = 'Style';
        // console.log('NEEEEEEEEEEEEW Style', { styles, value,propertyName, ...args });
        this.propertyName = propertyName;
        this.styles = styles;
        this.multiValueSeparator = ', ';
        Object.assign(this, args);
        if (this.outputThroughKey) {
            this.styles[this.outputThroughKey]
            // console.log('outputThroughKey', this.propertyName, this.outputThroughKey, this.styles[this.outputThroughKey]);
        }
    }
    get isEmpty(){
        // _value is null or undefined, or _value.value is null or undefined
        return this._value == null || this._value.value == null;
    }
    get css() {
        const value = this.value ?? this.getDefaultValue();
        //log out the value array
        const joinedValue = Array.isArray(value) ? value.map(v => v.css).join(this.multiValueSeparator) : value;
        return joinedValue;
    }
    get value() {
        return this._value;
    }
    get value() {
        return this._value;
    }
    set value(value) {
        this._value = value;
    }
    increment(value) {
        this.value = this.value + value;
    }
    decrement(value) {
        this.value = this.value - value;
    }
    getStyle(propertyName) {
        // if this.propertyName is same, then from this, or from styles
        return this.propertyName === propertyName ? this : this.styles.getStyle(propertyName);
    }
    createRange(min, max, step) {
        const $_arr =  Array.from({ length: (max - min) / step + 1 }, (_, i) => min + i * step);
        return $_arr.map(String);
    }
    getDefaultValue() {
        if (this.defaultValue !== undefined) {
            return this.defaultValue;
        }
        switch (this.propertyName) {
            case 'left':
            case 'top':
                return 'auto';
            case 'right':
            case 'bottom':
                return '0px';
            case 'transform-origin':
                // console.log('RETURNGI DEFUALT CENTER', this.propertyName);
                return 'center center';
            case 'transform':
                return '';
            default:
                return '';
        }
    }
    toJSON() {
        const { styles, ...rest } = this;
        return Object.assign({}, rest, { css: this.css });
    }
    toString() {
        return this.css;
    }
}

export default Style;