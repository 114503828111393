const baseStyleConfig = {
    positioningOffsets: {
        keys: [
            'top',
            'right',
            'bottom',
            'left',
            { key: 'inset' },
        ],
        defaultValue: {value:'auto', unit:''},
        units: ['%', 'px', 'cm', 'rem','em', '']
    },
    SizingOffsets: {
        keys: [
            { key: 'width', defaultValue: {value:100, unit:'%'} },
            { key: 'height', defaultValue: {value:'auto', adjustable: 100, unit:''} },
            'min-width',
            'max-width',
            'min-height',
            'max-height',
        ],
        defaultValue: {value:'auto', unit:''},
        units: ['%', 'px', 'cm', 'rem','em', ''],
        className: 'StyleWithUnit'
    },
    positioning: {
        keys: [

            { key: 'transform', defaultValue: {value:'none', unit:''}, units: [''],
        variants: [
            { key: 'rotate',outputThroughKey:'transform', className: 'StyleWithUnit' , defaultValue: {value:0, unit:'deg'}, units: ['deg', 'rad', 'turn'] },
            { key: 'rotateX',outputThroughKey:'transform',className: 'StyleWithUnit', defaultValue: {value:0, unit:'deg'}, units: ['deg', 'rad', 'turn'] },
            { key: 'rotateY',outputThroughKey:'transform',className: 'StyleWithUnit', defaultValue: {value:0, unit:'deg'}, units: ['deg', 'rad', 'turn'] },
            { key: 'scale',outputThroughKey:'transform', defaultValue: {value:1, unit:''}, className: 'StyleWithUnit',units: [''] }
        ]},
            { key: 'position', defaultValue: 'static' },
            'z-index',
            'overflow',
            'float',
            'clear'
        ],
        defaultValue: 'auto'
    },
    color: {
        keys: [
            'color',
            'background-color',
            'border-color',
            'outline-color',
            'caret-color',
            'fill',
            'stroke'
        ],
        defaultValue: 'inherit'
    },
    border: {
        keys: [
            { key: 'border', defaultValue:{value:'none', unit:''} },
            { key: 'border-width', defaultValue: 'medium' },
            { key: 'border-style', defaultValue:{value:'none', unit:''} },
            { key: 'border-radius', defaultValue: 0 }
        ],
        variants: [
            'top',
            'right',
            'bottom',
            'left'
        ]
    },
    font: {
        keys: [
            { key: 'font-size', defaultValue: 'medium' },
            { key: 'font-weight', defaultValue:{value:'none', unit:''}},
            { key: 'font-family', defaultValue: 'serif' },
            { key: 'font-style', defaultValue: 'normal' },
            { key: 'line-height', defaultValue: 'normal' },
            { key: 'text-align', defaultValue: 'start' },
            { key: 'letter-spacing', defaultValue: 'normal' },
            { key: 'text-decoration', defaultValue:{value:'none', unit:''} },
            { key: 'text-transform', defaultValue:{value:'none', unit:''} },
            { key: 'font-variant', defaultValue: 'normal' },
            { key: 'white-space', defaultValue: 'normal' }
        ]
    },
    other: {
        keys: [
            'opacity',
            'box-shadow',
            'text-shadow',
            'background-image',
            'background-repeat',
            'background-position',
            'background-size',
            {
                key: 'transform-origin',
                className: 'StyleAnchor',
                variants: [
                    {key:'x', outputThroughKey:'transform-origin', className: 'StyleWithUnit'},
                    {key:'y', outputThroughKey:'transform-origin', className: 'StyleWithUnit'},
                    {key: 'z', outputThroughKey:'transform-origin', className: 'StyleWithUnit', units: ['px', 'cm', 'rem','em'], defaultValue: {value:'', adjustable: 0, unit:''}}
                ],
                defaultValue: {value:0, unit:'%'},
                units: ['%','px', 'cm', 'rem','em']
            },
            'cursor',
            'visibility',
            'transition',
            'animation',
            'filter',
            'clip-path',
            'object-fit',
            'object-position'
        ],
        defaultValue: 'initial',
    },
    spacing: {
        keys: [
            { key: 'margin', defaultValue: 0 },
            { key: 'padding', defaultValue: 0 }
        ],
        variants: [
            'top',
            'right',
            'bottom',
            'left'
        ]
    }
};

const validAutoStyleKeysWithDefaults = {};

Object.values(baseStyleConfig).forEach(config => {
    const { keys: rawKeys, ...configWithoutKeys } = config;
    const keys = rawKeys.map(item => (typeof item === 'string' ? { ...configWithoutKeys, key: item } : {
        ...configWithoutKeys,
        ...item,
    }));

    keys.forEach(({ key, variants, ...rest }) => {
        // Add the base key with its default value and any additional properties
        validAutoStyleKeysWithDefaults[key] = {...rest };

        // Add the variants with their respective default values and any additional properties
        if (variants) {
            variants.forEach(variant => {
                if (typeof variant !== 'object') {
                    variant = { key: variant};
                }
                const variantKey = `${key}-${variant.key}`;
                delete variant.key;
                validAutoStyleKeysWithDefaults[variantKey] = { ...validAutoStyleKeysWithDefaults[key], ...variant };
            });
        }
    });
});
// const transformOriginValues = Object.entries(validAutoStyleKeysWithDefaults)
//     .filter(([key]) => key.includes('transform')).reduce((acc, [key, value]) => {
//         acc[key] = value;
//         return acc;
//     }, {});

// console.log('transformOriginValues', validAutoStyleKeysWithDefaults);

export default validAutoStyleKeysWithDefaults;