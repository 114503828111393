import Style from './base';

class StyleWithUnit extends Style {
    constructor(args) {
        super(args);
        this.the_class = 'StyleWithUnit';
        this.multiValueSeparator = ' ';
        this.defaultUnit = 'px';
    }
    get unit() {
        let unit = this._value?.unit;
        if (this.units && this.units.includes(unit)) {
            return unit;
        } else {
            if (this._value == null || this._value?.value == null) {
                return this.getDefaultValue().unit;
            } else if(this._value?.unit == null) {
                return this.units ? this.units[0] : this.getDefaultValue()?.unit;
            } else {
                return this._value.unit;
            }
        }
    }

    get value() {
        if (this._value == null || this._value.value == null) {
            return this.getDefaultValue().value;
        } else if (typeof this._value === 'object') {
            return this._value?.value ?? this.getDefaultValue().value;
        } else {
            return this._value;
        }
    }
    get css() {
        return `${this.value}${this.unit}`;
    }
    increment(value) {
        console.log('StyleWithUnit increment', value, this.value, this._value, this.getDefaultValue());
        let currentValue = this.parseFloat(this.value);
        if (isNaN(currentValue) || currentValue == null) {
            console.log('StyleWithUnit increment was NaN', currentValue);
            currentValue = this.parseFloat(this.getDefaultValue()?.adjustable ?? this.getDefaultValue()?.value);
        }
        if (isNaN(currentValue) || currentValue == null) {
            currentValue = 0;
        }
        this._value.value = currentValue + value;
    }
    decrement(value) {
        console.log('StyleWithUnit increment', value, this.value, this._value, this.getDefaultValue());
        let currentValue = this.parseFloat(this.value);
        if (isNaN(currentValue) || currentValue == null) {
            console.log('StyleWithUnit increment was NaN', currentValue);
            currentValue = this.parseFloat(this.getDefaultValue()?.adjustable ?? this.getDefaultValue()?.value);
        }
        if (isNaN(currentValue) || currentValue == null) {
            currentValue = 0;
        }
        this._value.value = currentValue - value;
    }
    set value(inputValue) {
        if (this._value === undefined) {
            this._value = {};
        }
        // verbose detailed console log
        
        if (typeof inputValue === 'string') {
            const result = this.parseValue(inputValue);
            this._value = result;
        } else if (typeof inputValue === 'object' && inputValue != null) {
            console.log('StyleWithUnit INTPUTVALUE zzzzzzzzz', inputValue);
            if (inputValue.value !== undefined) {
                this._value = inputValue.value;
                if (inputValue.unit !== undefined) {
                    this._value.unit = inputValue.unit;
                }
            }
        } else {
            this._value.value = inputValue;
        }
    }
    parseFloat(value) {
        const output = parseFloat(value);
        return isNaN(output) ? null : output;
    }
    parseValue(value) {
        const match = value.match(/^\s*(\-?\d+)(\D*)\s*$/);
        if (match) {
            let unit = match[2];
            if (!this.units || !this.units.includes(unit)) {
                unit = this.units ? this.units[0] : undefined;
            }
            return { value: this.parseFloat(match[1]), unit: unit };
        } else {
            return { value: value, unit: '' };
        }
    }
}


export default StyleWithUnit;