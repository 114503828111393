// canvas.js
import ArtBoard from './artBoard.js';

class Canvas {
    constructor({ app, board_types = [], boards = [], initial_fill = null, initial_color = null, ...args } = {}) {
        if (!app) {
            throw new Error('App is required to create a canvas.');
        }
        this.app = app;
        this.canvas = [];
        if (!Array.isArray(boards) || !boards.length) {
            boards = [{ initial_fill, initial_color }];
        }
        this.board_types = board_types;
        boards.forEach(board => this.addArtBoard(board));
        this.excludeFromJSON = ['the_class', 'app', 'excludeFromJSON'];
        return new Proxy(this, {
            get(target, prop, receiver) {
                // Handle method calls ending with 'OnBoards'
                if (Reflect.has(target, prop)) {
                    return Reflect.get(target, prop, receiver);
                }
                // if (prop == 'addVisualOnBoards') console.log('aaaaaaaaaaaaa2222222222222222224 prop', prop, receiver);
                let onBoards, onBoard, methodName;
                if (typeof prop === 'string') {
                    onBoards = prop.endsWith('__onSelectedBoards');
                    if (onBoards) {
                        methodName = prop.replace('__onSelectedBoards', '');
                    } else {
                        onBoard = prop.endsWith('__onSelectedBoard1');
                        if (onBoard) methodName = prop.replace('__onSelectedBoard1', '');
                    }
                }
                // console.log('aaaaaaaaaaaaa2222222222222222224  propB', prop, { onBoards, onBoard }, receiver);
                if (methodName) {
                    // console.log('aaaaaaaaaaaaa2222222222222222224 main propC', prop, { methodName, onBoards, onBoard }, receiver);
                    return (...args) => {
                        const result = receiver.getSelected().map(artBoard => {
                            if (typeof artBoard[methodName] === 'function') {
                                /* console.log */('aaaaaaaa artboard inside the loop', methodName, prop, artBoard);
                                return artBoard[methodName].call(artBoard, ...args);
                            } else if (Reflect.has(artBoard, methodName)) {
                                // console.warn(`${methodName} is a property on artBoard.`);
                                return Reflect.get(artBoard, methodName, artBoard);
                            } else {
                                console.warn(`Method ${methodName} not found on ArtBoard.`);
                            }
                        }).filter(result => result !== undefined);
                        return onBoard ? result[0] : result;
                    };
                }
                // Fallback to normal property/method using best practices ofReflect
                return Reflect.get(target, prop, receiver);
            }
        });
    }
    async getFont() {
        if (!this.font) {
            try {
                const familyName = 'Lexend';
                const url = 'https://vidavivida.es/assets/fonts/LexendDeca-VariableFont_wght.woff2';
                console.log('font getting started');
                const response = await fetch(url)
                console.log('font getting response', response);
                const blob = await response.blob()
                console.log('font getting blob', blob);
                const fontData = await this.blobToData(blob);
                console.log('font getting fontData', fontData);

                this.font = `@font-face {
                    font-family: "${familyName}";
                    src: url("${fontData}");
                }`
            } catch (err) {
                console.error("Error getting font face", err)
                return ""
            }
        }
        return this.font;
    }
    async blobToData(blob) {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result + ""); // Resolve when reading is complete
            reader.onerror = () => reject(reader.error); // Reject if an error occurs
            reader.readAsDataURL(blob); // Start reading the file
        });
    }
    addArtBoard({ visuals = [], initial_fill = null, initial_color = null, ...args } = {}) {
        const newArtBoard = new ArtBoard({ canvas: this, visuals, initial_fill, initial_color, ...args });
        newArtBoard.selected = false; // Initialize with unselected state
        this.canvas.push(newArtBoard);
        return newArtBoard;
    }
    async convertSelectedBoardsToImages() {
        const selectedBoards = this.getSelected();
        const images = [];
        this.app.saving = 'images';
        try {
            
            for (const board of selectedBoards) {
                this.app.saving = board.id + ': ' + board.name;
                images.push(await board.convertToImage());
            }
            this.app.saving = 'item';
            const result = await this.updatePage();
            if (result.status === 'ok') {
                this.app.toast('Success', 'Page updated successfully.', 'success');
            } else {
                this.app.toast('Error', result.message, 'error');
            }
            this.app.saving = false;
        } catch (error) {
            console.error('Error converting selected boards to images:', error);
            this.app.toast('Error', 'Error converting selected boards to images.', 'error');
            this.app.saving = false;
        }
    }
    selectAll() {
        this.canvas.forEach(board => board.selected = true);
    }
    selectArtBoard(id) {
        const artBoard = this.canvas.find(artBoard => artBoard.id === id);
        if (artBoard) {
            artBoard.selected = true;
        } else {
            console.warn('Invalid artboard id');
        }
    }

    unselectArtBoard(id) {
        const artBoard = this.canvas.find(artBoard => artBoard.id === id);
        if (artBoard) {
            artBoard.selected = false;
        } else {
            console.warn('Invalid artboard id');
        }
    }
    getSelectedOrFirst() {
        return this.getSelected().length ? this.getSelected() : [this.canvas[0]];
    }
    getSelected() {
        /* console.log */("AAAAAAAAArtboard inside getSelected", this);
        return this.canvas.filter(board => board.selected);
    }
    getAll() {
        return this.canvas;
    }
    clearAll(exceptForBoard = null) {
        this.canvas.forEach(board => {
            if (!exceptForBoard || exceptForBoard !== board) {
                board.clearAll();
                board.selected = false;
            }
        });
    }
    async updatePage() {
        // await new Promise(resolve => setTimeout(resolve, 2000));
        const response = await fetch("/api/pages/" + this.app.itemId, {
            method: "PATCH",
            headers: {
                "X-CSRF": csrf,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                visuals: JSON.stringify(this.getAll(), null, 2),
                ...(this.postImageField && this.postImageField.length ? { post_images: this.postImageField } : {})
            })
        });
        return response.json();
    }
    get postImageField() {
        // It should get the type and the uploadedFileId and type from each artboard and ouptut an array of objects in the following syntax each{image:, type_in_post:}
        let fromCanvas = this.canvas.map((artBoard ,index)=> {
            if (artBoard.uploadedFileId) {
                return {
                    id: index,
                    image: [artBoard.uploadedFileId],
                    type_in_post: artBoard.type ?? null
                };
            }
        }).filter(obj => obj);
        return [...fromCanvas, ...(this._postImagesNotFromCanvas || [])].map(obj => { const {id, ...rest} = obj; return rest;});
    }
    set postImageField(value) {
        // Check each value and see if it matches the uploadedFileId of any artboard and remove it from the values before saving the values to the _postImagesNotFromCanvas.
        this.canvas.forEach(artBoard => {
            const match = value.find(obj => obj.image.includes(artBoard.uploadedFileId));
            if (match) {
                artBoard.type = match.type_in_post;
                value = value.filter(obj => !obj.image.includes(artBoard.uploadedFileId));
            }
        });
        this._postImagesNotFromCanvas = value;
    }
    toJSON() {
        // returns also getter props instead of their underlying "_" props
        const descriptors = Object.getOwnPropertyDescriptors(Object.getPrototypeOf(this));
        const getterProps = Object.keys(descriptors).reduce((acc, key) => {
            if (typeof descriptors[key].get === 'function' && !this.excludeFromJSON.includes(key)) {
                acc[key] = this[key];
            }
            return acc;
        }, {});
        // console.log('ZZZZZZZZZZZZZZZZZZZZZZ88', getterProps,Object.entries(this));
        return {
            ...getterProps,
            ...Object.fromEntries(Object.entries(this).filter(([key]) => !this.excludeFromJSON.includes(key) && (!key.startsWith('_') || !(key.slice(1) in getterProps)))),
            // ...this,
        };
    }
}

export default Canvas;
