import Visual from './classes/visual.js';
import Canvas from './classes/canvas.js';


export default ({ itemId = null, the_visuals = [], board_types = [], item_image_structure = [], post_slug = null, post_name = null, initial_fill = null, initial_color = null } = {}) => {
    return (itemId == null) ? {
        app_valid: false,
        message_why: 'Missing required parameter ' +
            [
                !itemId ? 'itemId' : ''].filter(Boolean).join(', '),

    } :
        {
            saving: false,
            message_why: '',
            toast_hovering: false,
            toast_messages: [],
            board_types: board_types,
            toast(title, message, type = 'info') {
                const toastMessage = { title, message, hidden: false, type };
                this.toast_messages.push(toastMessage);
                setTimeout(() => {
                    // Replace the object reference to ensure reactivity
                    const index = this.toast_messages.indexOf(toastMessage);
                    if (index !== -1) {
                        if (this.toast_hovering) {
                            this.toast_messages[index].hidden = true;
                        } else {
                            this.toast_messages.splice(index, 1);
                        }
                    }
                }, 5000);
            },
            app_valid: true,
            hidetext: true,
            itemId: itemId,
            show_artboard: true,
            the_visuals: the_visuals,
            post_slug: post_slug,
            post_name: post_name,
            _myvisuals: [],
            initial_fill: initial_fill,
            initial_color: initial_color,
            defaultStyles: {
                fill: null,
                color: null,
                left: 'auto',
                right: 0,
                top: 'auto',
                bottom: 0,
                'transform-origin': null,
                transform: null
            },
            get visuals() {
                return this._myvisuals ?? [];
            },
            moveVisual(visual, direction) {
                const index = this._myvisuals.findIndex(i => i.id === visual.id);
                console.log('moving', visual.id, direction, index);
                if (visual.type === 'bg' || (direction === -1 && index <= 1) || (direction === 1 && index === this._myvisuals.length - 1)) {
                    console.log('cannot move', visual.id, direction, index);
                    return;
                }
                [this._myvisuals[index], this._myvisuals[index + direction]] = [this._myvisuals[index + direction], this._myvisuals[index]];
            },
            addVisual(visual = {}, visual_index = null) {
                if (!Array.isArray(this._myvisuals)) {
                    this._myvisuals = [];
                }
                console.log('adding visual at', visual_index, (visual_index === 0 || visual_index) ? visual_index : this._myvisuals.length);
                visual.internal_id = visual.internal_id ?? 'id_' + this._myvisuals.length + '_' + (Math.random().toString(36)).substring(7);
                const the_visual = new Visual(visual);
                this._myvisuals.splice((visual_index === 0 || visual_index) ? visual_index + 1 : this._myvisuals.length, 0, the_visual);
                return the_visual;
            },
            colorsObject: null,
            canvas: null,
            rootElement: null,
            item_image_structure: item_image_structure,
            initApp(el) {
                this.rootElement = el;
                this.colorsObject = VV.colors;
                console.log('initing cssart board', this.colorsObject, board_types);
                this.canvas = new Canvas({ app: this, board_types: this.board_types, boards: this.the_visuals, initial_fill, initial_color });
                if (this.item_image_structure && Array.isArray(item_image_structure) && item_image_structure.length > 0) {
                    this.canvas.postImageField = this.item_image_structure;
                }
                if (this.canvas.getSelected().length === 0) {
                    const firstVisualOnFirstBoard = this.canvas.getAll()?.[0]?.getAll()?.[0];
                    if (firstVisualOnFirstBoard) {
                        firstVisualOnFirstBoard.selected = true;
                    }
                }
                console.log("AAAAAAAACanvas main", this.canvas);
                this.toast('Welcome to the CSS Artboard', 'You can use the arrow keys to move, resize, rotate, flip, snap, and stack visuals. Press + to add a new text visual, and press + while holding alt to add a new artboard.');
                // [{title: 'yessss', type:'error', message: 'This is a really good message!'},{title: 'Woo hoo?!', type:'success', message: 'This is a really good and extremely long logn lnog lnogn theaixlkf fhsdlkfhs sdhlkf  message!'}].forEach(({title, message, type}) => {
                //     this.toast(title, message, type);
                // });
                // this.canvas.addArtBoard({the_visuals: this.the_visuals, initial_fill: this.initial_fill, initial_color: this.initial_color});
                // this.canvas.addArtBoard({the_visuals: this.the_visuals, initial_fill: this.initial_fill, initial_color: this.initial_color});
                // this.canvas.addArtBoard({the_visuals: this.the_visuals, initial_fill: this.initial_fill, initial_color: this.initial_color});
                // this.artBoard.unselectVisual(viz);
                // this._myvisuals = this.canvas.getAll()[0].visuals;

                // ensure all visuals have at least left, right, bottom, top set in their styles.
                // this.loadSVGs();
                this.initialised = true;
            },
            initialised: false,
            getIconsController: null,
            getIconsResults: [],
            loading_icons: false,
            getIcons(keyword) {
                // Abort previous fetch request
                if (this.getIconsController !== null) {
                    this.getIconsController.abort();
                }

                // Create a new AbortController
                this.getIconsController = new AbortController();
                this.loading_icons = true;
                this.getIconsResults = [];
                fetch("/api/pages/files+icons/files/search?q=" + keyword + "&limit=100&offset=0", {
                    method: "GET",
                    signal: this.getIconsController.signal, // Pass the signal to the fetch request
                    headers: {
                        "X-CSRF": csrf,
                        "Content-Type": "application/json"
                    }
                }).then(response => response.json()).then(response => {
                    console.log('DONEEEEEEE', keyword, response)
                    this.getIconsResults = response.data;
                    this.loading_icons = false;
                }).catch(error => {
                    this.loading_icons = false;
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        console.log('ERROOOOOOOOOOR', error)
                        // something went wrong
                    }
                });
            },
            deleteVisual() {
                let nextSelectedIndex = null;

                for (let i = this._myvisuals.length - 1; i >= 0; i--) {
                    if (this._myvisuals[i].selected) {
                        nextSelectedIndex = i + 1; // Select the next visual up
                        this._myvisuals.splice(i, 1);
                    }
                }

                // If the next visual up is not available, select the next visual down
                if (nextSelectedIndex >= this._myvisuals.length) {
                    nextSelectedIndex = this._myvisuals.length - 1;
                }

                // If there are no visuals left, nextSelectedIndex will be -1
                if (nextSelectedIndex >= 0) {
                    this.selectVisual(this._myvisuals[nextSelectedIndex]);
                }
            },
            htmlcanvas: null,
            portrait_file_id: null,
            landscape_file_id: null,
            async convertToImage() {
                const fontFace = await this.getFontFace('Lexend', 'https://vidavivida.es/assets/fonts/LexendDeca-VariableFont_wght.woff2');
                document.querySelectorAll('#visuals .graphic svg').forEach(a => this.appendStyle(fontFace, a));
                document.documentElement.style.overflow = 'hidden';
                await html2canvas(document.querySelector('#visuals .graphic'), {
                    allowTaint: true,
                    scale: 5,
                    removeContainer: true,
                }).then(canvas => {
                    this.htmlcanvas = canvas;
                }).catch(error => {
                    console.error('html2canvassss Error:', error);
                });
                document.documentElement.style.overflow = '';
            },
            async convertToImageOld() {
                const fontFace = await this.getFontFace('Lexend', 'https://vidavivida.es/assets/fonts/LexendDeca-VariableFont_wght.woff2');
                document.querySelectorAll('#visuals .graphic svg').forEach(a => this.appendStyle(fontFace, a));
                document.documentElement.style.overflow = 'hidden';
                await html2canvas(document.querySelector('#visuals .graphic'), {
                    allowTaint: true,
                    scale: 5,
                    removeContainer: true,
                }).then(canvas => {
                    this.htmlcanvas = canvas;
                }).catch(error => {
                    console.error('html2canvassss Error:', error);
                });
                document.documentElement.style.overflow = '';
            },
            async deletePostFile() {
                const response = await fetch("/api/pages/files+images+posts/files/post-" + this.post_slug + ".jpg", {
                    method: "DELETE",
                    headers: {
                        "X-CSRF": csrf
                    }
                });
                return response.json();
            },
            async uploadPostFile(blob) {
                const formData = new FormData();
                formData.append('file', blob, `post-${this.post_slug}.jpg`);
                formData.append('name', `Post: ${this.post_name}`);
                formData.append('template', 'post');
                const response = await fetch("/api/pages/files+images+posts/files", {
                    method: "POST",
                    headers: {
                        "X-CSRF": csrf
                    },
                    body: formData
                });
                return response;
            },
            async updatePage() {
                console.log("this.portrait_file_id", this.portrait_file_id);
                const response = await fetch("/api/pages/" + this.itemId, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF": csrf,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        visuals: JSON.stringify(this.canvas.getAll(), null, 2),
                        ...(this.portrait_file_id ? { post_images: [{ image: this.portrait_file_id }] } : {})
                    })
                });
                return response.json();
            },
            async saveToPost() {
                let filteredVisuals = this.visuals.filter(visual => (visual.type === 'bg' || (visual.type === 'svg' && (visual.icon || visual.svg)) || (visual.type === 'text' && visual.content)));
                if (this.htmlcanvas) {
                    let blob = await new Promise(resolve => this.htmlcanvas.toBlob(resolve, 'image/jpeg', .9));
                    try {
                        let response = await this.uploadPostFile(blob);
                        if (!response.ok) {
                            if (response.status === 400) {
                                let data = await response.json();
                                if (data.message && data.message.indexOf('already exists') !== -1) {
                                    await this.deletePostFile(this.post_slug);
                                    response = await this.uploadPostFile(blob);
                                    if (response.ok) {
                                        data = await response.json();
                                        if (data.data?.id) this.portrait_file_id = data.data.id;
                                    }
                                }
                            } else {
                                throw new Error('Network response was not ok');
                            }
                        } else {
                            let data = await response.json();
                            if (data.data?.id) this.portrait_file_id = data.data.id;
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }

                try {
                    const page = await this.updatePage(filteredVisuals);
                } catch (error) {
                    console.error('Error:', error);
                }
            },
            saveToPostOld() {
                // filtered Visuals should include everythign but certain keys per visual such as svg.
                let filteredVisuals = this.visuals.map(visual => {
                    const {
                        selected,
                        ...filteredVisual
                    } = visual;
                    return filteredVisual;
                }).filter(visual => (visual.type === 'bg' || (visual.type === 'svg' && (visual.icon || visual.svg)) || (visual.type === 'text' && visual.content)));
                console.log("saving visuals", filteredVisuals);
                // return;
                // fetch all files under pages/files/images/files
                if (this.htmlcanvas) {
                    console.log('CANVASSSSSSSSSNOW');
                    this.htmlcanvas.toBlob(blob => {
                        const formData = new FormData();
                        console.log('BLOBBBBBBBBBBBBBING', blob);
                        formData.append('file', blob, `post-${this.post_slug}.jpg`);
                        formData.append('name', `Post: ${this.post_name}`);
                        fetch("/api/pages/files+images+posts/files", {
                            method: "POST",
                            body: formData,
                            headers: {
                                "X-CSRF": csrf
                            }
                        }).then((response) => {
                            if (!response.ok) {
                                if (response.status === 400) { //&& response.json().error.indexOf('already exists') !== -1){
                                    response.json().then(
                                        data => {
                                            if (data.message && data.message.indexOf('already exists') !== -1) {
                                                // delete file with DELETE /api/pages/:id/files/:filename
                                                fetch("/api/pages/files+images+posts/files/post-" + this.post_slug + ".jpg", {
                                                    method: "DELETE",
                                                    headers: {
                                                        "X-CSRF": csrf
                                                    }
                                                }).then(response => response.json()).then(response => {
                                                    console.log('DELETED', response);
                                                    console.log('Already exists', data);
                                                    fetch("/api/pages/files+images+posts/files", {
                                                        method: "POST",
                                                        headers: {
                                                            "X-CSRF": csrf
                                                        },
                                                        body: formData
                                                    }).then(response => response.json()).then(response => {
                                                        console.log('UPDATED', response);
                                                    }).catch(error => {
                                                        console.log('ERROOOOOOOOOOR', error);
                                                    });
                                                }).catch(error => {
                                                    console.log('ERROOOOOOOOOOR', error);
                                                });

                                            }
                                        }
                                    );
                                } else {
                                    throw new Error('Network response was not ok');
                                }
                            } else return response.json();
                        }).then(response => {
                            console.log('FDONEEEEEEE', response)
                            // do something with the page data
                        }).catch(error => {
                            console.log('FERROOOOOOOOOOR', error);

                            // something went wrong
                        });
                    }, 'image/jpeg', 0.9);
                }
                fetch("/api/pages/" + this.itemId, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF": csrf,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        visuals: filteredVisuals
                    })
                }).then(response => response.json()).then(response => {
                    const page = response.data;
                    console.log('DONEEEEEEE', response)
                    // do something with the page data
                }).catch(error => {
                    console.log('ERROOOOOOOOOOR', error)
                    // something went wrong
                });

            },
            unusedBgColors: [],
            ROW_OCCUPIED: 1, // 01 in binary
            COLUMN_OCCUPIED: 2, // 10 in binary
            grid: {
                fillRow: true,
                rowCount: 9,
                columnCount: 6,
                overlap: 0, // Configure the overlap
                cells: []
            },
            getOnlySelectedVisual() {
                const selectedVisuals = this.canvas.getSelected__onSelectedBoards()?.flat();
                return selectedVisuals.length === 1 ? selectedVisuals[0] : {};
            },
            getOnlySelectedArtBoard() {
                const selectedBoards = this.canvas.getSelected();
                return selectedBoards.length === 1 ? selectedBoards[0] : {};
            },
            toggleVisualSelection(visual) {
                visual.selected = !visual.selected;
                if (visual.selected) {
                    this.selectVisual(visual);
                } else {
                    this.unselectVisual(visual);
                }
            },
            selectVisual(visual) {
                visual.selected = true;
                this.adding = false;
            },
            unselectVisual(visual) {
                visual.selected = false;
            },
            getSelected() {
                return this.visuals.filter(v => v.selected);
            },
            loadSVG(visual) {
                // load the svg from the icon_url and set it as the svg for the visual
                if (visual.icon_url) {
                    fetch(visual.icon_url).then(response => response.text()).then(svg => {
                        svg = svg.replace(/width=".*?"/, '')
                            .replace(/height=".*?"/, '')
                            .replace(/<svg /, '<svg class="min-h-4 min-w-4"');
                        visual.svg = svg;
                        // console.log('new svg',svg);
                    });
                } else if (visual.pasted_svg) {
                    visual.svg = visual.pasted_svg.replace(/width=".*?"/, '')
                        .replace(/height=".*?"/, '')
                        .replace(/<svg /, '<svg class="min-h-4 min-w-4"');
                    delete visual.pasted_svg;
                }
            },
            updateVisualID(visual, the_id = '') {
                visual.id = 'OOOOOOOOOOOPS🙀';
                return;
                console.log('updating visual ID', visual, 'to', the_id);
                if (!the_id) {
                    the_id = visual.type == 'text' ? visual.content : visual.icon;
                }
                the_id = the_id?.replace(/[^a-z0-9\-_\s]+/gi, '').replace(/\s+/, ' ').split(' ').reduce((acc, word) => (acc.length <= 20) ? acc + (acc.length ? '_' : '') + word : acc, '').replace(/^[0-9_]+/, '').toLowerCase();
                if (!the_id) {
                    the_id = 'rand_' + Math.round(Math.random() * 1000000000)
                };
                // check if ID is unique, if not, append a number to it.
                console.log('updating visual ID checking for', the_id);
                let i = 1;
                while (this._myvisuals.find(v => (v !== visual && v.id === the_id))) {
                    // if ending in _\d+ just increment the number, otherwsie add _+i
                    if (the_id.match(/_\d+$/gi)) {
                        the_id = the_id.replace(/_\d+$/gi, '') + '_' + i;
                    } else {
                        the_id = the_id + '_' + i;
                    }
                    i++;
                }
                visual.id = the_id;
            },
            get getFirstSelectedVisualZ() {
                return this.getSelected[0] || {};
            },
            selectedToolbarButton: null,
            adding: false,
            editng: false,
            get toolbarButtons() {
                const selectedTypes = this.canvas.type__onSelectedVisuals__onSelectedBoards().flat();
                return {
                    "+": "add +",
                    ... !selectedTypes || selectedTypes.length == 0 ? {} : {
                        ...(!selectedTypes.includes('bg') && {
                            "a": "anchor"
                        }),
                        ...(!selectedTypes.includes('bg') && {
                            "r": "rotate"
                        }),
                        ...(!selectedTypes.includes('bg') && {
                            "s": "resize"
                        }),
                        ...(!selectedTypes.includes('bg') && {
                            "p": "snap"
                        }),
                        ...(selectedTypes.includes('text') && {
                            "b": "boldness"
                        }),
                        ...(!selectedTypes.includes('bg') && {
                            "f": "flip"
                        }),
                        ...(!selectedTypes.includes('bg') && {
                            "m": "move"
                        }),
                        "c": "color",
                        ...(!selectedTypes.includes('bg') && {
                            "t": "stack"
                        }),
                        ...(!selectedTypes.includes('bg') && {
                            "*": "clone"
                        }),
                        ...(!selectedTypes.includes('bg') && {
                            "_8": "delete"
                        }),
                    },
                    "_clone": "clone board",
                    "_13": "save ⏎",
                    // "_save": "save old",
                    "_27": "close ␛",
                };
            },
            visualEditorKeyEvent(event) {
                // for non-event calls
                if (!(event instanceof Event)) {
                    event = Object.assign({
                        target: null,
                        ctrlKey: null,
                        key: null,
                        keyCode: null,
                        code: null,
                        altKey: null,
                        shiftKey: null,
                        metaKey: null,
                        preventDefault: () => { }
                    }, event);
                }
                // console.log(event.key, event.keyCode, event.code, event.ctrlKey, event.altKey, event.shiftKey, event.metaKey);
                console.log('VEKE selectedToolbarButton', this.selectedToolbarButton, event);
                if (event.target?.tagName.toLowerCase() === 'input') {
                    return;
                }
                //check if key is one of the toolbarButtons, and set the selectedToolbarButton to the corresponding value
                // console.log(this.selectedToolbarButton,event);
                if (this.toolbarButtons[event.key] || this.toolbarButtons['_' + event.keyCode]) {
                    console.log("Keys");
                    this.selectedToolbarButton = this.toolbarButtons[event.key] || this.toolbarButtons['_' + event.keyCode];
                    if (this.selectedToolbarButton == 'add +' && event.altKey) {
                        // add a new artboard, after clearing selected artbords
                        this.canvas.clearAll();
                        this.canvas.addArtBoard({type:'default'}).selected = true;
                        document.querySelector('.ARTBOARD-EDITOR #add-type-input').focus();
                        this.selectedToolbarButton = null;
                        return;
                    }
                    if (this.selectedToolbarButton == 'add +' && !event.altKey) {
                        const selectedBoards = this.canvas.getSelected();
                        this.canvas.clearAll__onSelectedBoards();
                        selectedBoards.map((b) => {
                            return b.addVisual({ type: 'text', content: null }).selected = true });
                        document.querySelector('.VISUAL-EDITOR #add-type-input').focus();
                        this.selectedToolbarButton = null;
                        return;
                    }
                    if (this.selectedToolbarButton == 'clone') {
                        this.canvas.getSelected__onSelectedBoards().flat().forEach(visual => {
                            const the_visual = visual.artboard?.addVisual({
                                ...JSON.parse(JSON.stringify(visual)),
                                id: '',
                                selected: true
                            });
                            visual.selected = false;
                            the_visual.updateVisualID(visual.id.replace(/_\d+$/gi, ''));
                            the_visual.move('bottom', 10);
                        });
                        this.selectedToolbarButton = null;
                        return;
                    }
                    if (this.selectedToolbarButton == 'save ⏎') {
                        this.canvas.convertSelectedBoardsToImages();
                        return;
                    }
                    if (this.selectedToolbarButton == 'clone board') {
                        const selectedBoards = this.canvas.getSelected();
                        for (const board of selectedBoards) {
                            const the_board = this.canvas.addArtBoard({
                                ...JSON.parse(JSON.stringify(board)),
                                id: null
                            });
                            console.log("cloning board", { selected: board.selected, the_board: the_board.selected });
                            the_board.selected = true;
                            board.selected = false;
                            console.log("cloning board after", { selected: board.selected, the_board: the_board.selected });
                        }
                        this.selectedToolbarButton = null;
                        return;
                    }
                    if (this.selectedToolbarButton == 'close ␛') {
                        this.canvas.clearAll__onSelectedBoards();
                        this.selectedToolbarButton = null;
                        return;
                    }
                    if (this.selectedToolbarButton == 'save old') {
                        this.convertToImage().then(() => {
                            this.saveToPost();
                        });
                        return;
                    }
                    if (this.selectedToolbarButton == 'delete') {
                        this.canvas.deleteVisual__onSelectedBoards();
                        this.selectedToolbarButton = null;
                        return;
                    }
                } else if (event.key === 'ArrowUp' || event.key === 'ArrowRight' || event.key === 'ArrowDown' || event.key === 'ArrowLeft') {
                    console.log("Arrows", event);
                    event.preventDefault();
                    const direction = ['increase', 'increase', 'decrease', 'decrease'][
                        ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].indexOf(event.key)
                    ];
                    if (!this.selectedToolbarButton) this.selectedToolbarButton = 'move';
                    if (event.ctrlKey || ['move', 'snap', 'resize', 'rotate', 'flip'].includes(this.selectedToolbarButton)) {
                        console.log("moves");
                        // console.log('pre0', JSON.stringify(this.posts[0].visuals, (key, value) => ['colorClass', 'grid', 'xRange', 'yRange', 'selected', 'colorIndex'].includes(key) ? undefined : value));
                        this.visualEditorMoveEvent(event);
                    } else if (!event.ctrlKey && this.selectedToolbarButton === 'stack') {
                        this.canvas.getSelected().forEach(
                            board => { board.getSelected().forEach(visual => {  board.moveVisual(visual, direction === 'decrease' ? -1 : 1); })}
                        );
                    } else if (!event.ctrlKey && this.selectedToolbarButton === 'boldness') {
                        this.canvas.changeFontWeight__onSelectedVisuals__onSelectedBoards(direction, this.magnitude(event, 10));
                    } else if (!event.ctrlKey && this.selectedToolbarButton === 'color') {
                        console.log("colorZZ");
                        this.canvas.changeColor__onSelectedVisuals__onSelectedBoards(direction, this.magnitude(event, 10));
                        // this.getSelected().forEach(visual => {
                        //     const $_index = (visual.colorIndex !== undefined) ? visual.colorIndex + (direction === 'decrease' ? -1 : 1) : 0;
                        //     visual.colorIndex = $_index < 0 ? this.colorsObject.colors().length() : $_index;
                        //     // console.log('colorIndex', 'layer-' + visual.id, VV.colors.getColorFor('layer-' + visual.id, true, null, visual.colorIndex, 0), visual.colorIndex);
                        //     this.matchVisualColorFromIndex(visual);
                        // });

                    } else if (!event.ctrlKey && this.selectedToolbarButton === 'anchor') {
                        console.log("anchor");
                        this.canvas.anchor__onSelectedVisuals__onSelectedBoards(direction, this.magnitude(event, 10));
                    } else {
                        console.log("other arrow");
                    }
                    // console.log('post0', JSON.stringify(this.posts[0].visuals, (key, value) => ['colorClass', 'grid', 'xRange', 'yRange', 'selected', 'colorIndex'].includes(key) ? undefined : value));


                } else {
                    // console.log("other event");
                }
            },
            magnitude(event, normal, micro, macro) {
                // if shift micro, if alt macro, else normal
                // if micro or macro not provided, make them by a factor of 10.
                micro = micro || normal / 10;
                macro = macro || normal * 10;
                return event.shiftKey ? micro : event.altKey ? macro : normal;
            },
            throttledVisualEditorKeyEvent: Alpine.throttle(function (event) {
                this.visualEditorKeyEvent(event);
            }, 10),
            matchVisualColorFromIndex(visual, newIndex = null) {
                if (newIndex !== null) {
                    visual.colorIndex = newIndex;
                }
                visual.styles.color = VV.colors.getColorFor('layer-' + visual.id, true, null, visual.colorIndex, 0).color;
                visual.styles.fill = visual.styles.color
            },
            getTransformFunctionValue(visual, functionName) {
                const defaultFunctions = {
                    rotate: '0deg',
                    rotateX: '0deg',
                    rotateY: '0deg',
                    scale: '1',
                    translate: '0%, 0%',
                    skew: '0deg, 0deg'
                };
                const transformFunctions = visual.styles.transform ? visual.styles.transform.split(' ').map(func => func.trim()) : [];
                console.log("FUNCTIONSSSSSSSSSS", transformFunctions)
                const functionIndex = transformFunctions.findIndex(func => func.startsWith(functionName + '('));
                if (functionIndex !== -1) {
                    return transformFunctions[functionIndex].slice(functionName.length + 1, -1);
                }
                return defaultFunctions[functionName];
            },
            setTransformFunctionValue(visual, functionName, newValue) {
                const transformFunctions = visual.styles.transform ? visual.styles.transform.split(' ').map(func => func.trim()) : [];
                const functionIndex = transformFunctions.findIndex(func => func.startsWith(functionName + '('));
                if (functionIndex !== -1) {
                    transformFunctions.splice(functionIndex, 1);
                }
                transformFunctions.push(`${functionName}(${newValue})`);
                visual.styles.transform = transformFunctions.join(' ');
            },
            visualEditorMoveEvent(event) {
                // determine css coordinate property name based on ArrowX value of the event i.e. up => top, right, down => bottom, left
                if (this.canvas.getSelected__onSelectedBoards().flat().length < 1) return;
                const towards = ['top', 'right', 'bottom', 'left'][
                    ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].indexOf(event.key)
                ];
                const axis = ['y', 'x', 'y', 'x'][
                    ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].indexOf(event.key)
                ];
                const pivot = ['x', 'y', 'x', 'y'][
                    ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].indexOf(event.key)
                ];
                const direction = ['increase', 'increase', 'decrease', 'decrease'][
                    ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].indexOf(event.key)
                ];
                const opposite_of_towards = ['bottom', 'left', 'top', 'right'][
                    ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].indexOf(event.key)
                ];
                if (this.selectedToolbarButton === 'move' || event.ctrlKey) {
                    this.canvas.move__onSelectedVisuals__onSelectedBoards(towards, this.magnitude(event, 10, 1, 100));
                    return;
                }
                if (!event.ctrlKey && this.selectedToolbarButton === 'resize') {
                    this.canvas.scale__onSelectedVisuals__onSelectedBoards(direction, this.magnitude(event, 10, 1, 100));
                    return;
                }
                if (!event.ctrlKey) {
                    if (this.selectedToolbarButton === 'rotate') {
                        console.log('rotate PQLJTRU');
                        this.canvas.rotate__onSelectedVisuals__onSelectedBoards(direction, this.magnitude(event, 22.5, 1, 90));
                        return;
                    }
                    if (this.selectedToolbarButton === 'snap') {
                        this.canvas.snap__onSelectedVisuals__onSelectedBoards(towards);
                        return;
                    }
                    // handle flip using rotateX or rotateY toggling between 0deg and 180deg
                    if (this.selectedToolbarButton === 'flip') {
                        this.canvas.flip__onSelectedVisuals__onSelectedBoards(axis, this.magnitude(event, 10, 1));
                        return;
                    }

                }
            },
            adjustPosition(visual, towards, amount = 1) {
                visual.move(towards, amount);
                return;
            },
            isMonochrome(color) {
                const hsl = tinycolor(color).toHsl();
                return hsl.s < 0.1; // Check if the saturation is less than 0.1
            },
            invertColor(hex) {
                let color = (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase();
                return color;
            },
            getValuesAtIndices(array, indices) {
                return indices.map(index => array[index]);
            },
            getRandomIndices(length, numIndices = 5) {
                if (length <= numIndices) {
                    return Array.from({
                        length
                    }).map((_, i) => i);
                }

                let minDistance = Math.floor(length / 3 / 3);
                const indices = [];

                const isTooClose = (index) => indices.some(i => Math.abs(i - index) < minDistance);

                while (indices.length < numIndices) {
                    let randomIndex;
                    let attempts = 0;

                    do {
                        randomIndex = Math.floor(Math.random() * length);
                        attempts++;
                        if (attempts > length * 2) {
                            minDistance = Math.floor(minDistance / 3);
                            attempts = 0;
                        }
                    } while (isTooClose(randomIndex));

                    indices.push(randomIndex);
                }

                return indices;
            },
            blobToData(blob) {
                return new Promise(resolve => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result + "")
                    reader.readAsDataURL(blob)
                })
            },
            async getFontFace(familyName, url) {
                try {
                    const response = await fetch(url)
                    const blob = await response.blob()
                    const fontData = await this.blobToData(blob)

                    return `@font-face {
                        font-family: "${familyName}";
                        src: url("${fontData}");
                    }`
                } catch (err) {
                    console.error("Error getting font face", err)
                    return ""
                }
            },
            appendStyle(css, target) {
                const styleEl = document.createElement("style")
                styleEl.appendChild(document.createTextNode(css))
                target.appendChild(styleEl)
            }
        }
}