import StyleWithUnit from './withUnit';

class StyleShorthandBoxModelWithUnit extends StyleWithUnit {
    constructor(args) {
        super(args);
        this.the_class = 'ShorthandBoxWithUnit';
        this.multiValueSeparator = /\s+/;
        this.defaultUnit = 'px';
    }

    get css() {
        return this.packBoxModel();
    }
    increment(value) {
        this._value.value = this._value.value + value;
    }
    decrement(value) {
        this._value.value = this._value.value - value;
    }
    parseBoxModel(value) {
        let values = value.split(this.multiValueSeparator);
        let top = values[0];
        let right = values[1] || values[0];
        let bottom = values[2] || values[0];
        let left = values[3] || values[1] || values[0];
        // console.log('StyleShorthandBoxModelWithUnit parseBoxModel', value, values,this.multiValueSeparator, { top, right, bottom, left });
        return { top, right, bottom, left };
    }
    getLonghandPrefix() {
        return this.propertyName !== 'inset' ? this.propertyName + '-' : '';
    }
    packBoxModel() {
        const top = this.styles[`${this.getLonghandPrefix()}top`].css;
        const right = this.styles[`${this.getLonghandPrefix()}right`].css;
        const bottom = this.styles[`${this.getLonghandPrefix()}bottom`].css;
        const left = this.styles[`${this.getLonghandPrefix()}left`].css;

        if (top === right && right === bottom && bottom === left) {
            // All four sides are the same.
            return top;
        } else if (top === bottom && right === left) {
            // Top and bottom are the same, right and left are the same.
            return `${top} ${right}`;
        } else if (right === left) {
            // Top, bottom, and sides are distinct.
            return `${top} ${right} ${bottom}`;
        } else {
            // All four sides are distinct.
            return `${top} ${right} ${bottom} ${left}`;
        }
    }
    set value(inputValue) {
        if (this._value === undefined) {
            this._value = {};
        }
        // verbose detailed console log
        if (typeof inputValue === 'string') {
            const boxModelValues = this.parseBoxModel(inputValue);
            this._value = {};
            for (const side in boxModelValues) {
                this._value[side] = this.parseValue(boxModelValues[side]);
                this.styles[`${this.getLonghandPrefix()}${side}`] = boxModelValues[side];
            }
        } else if (typeof inputValue === 'object' && inputValue != null) {
            if (inputValue.value !== undefined) {
                this._value = inputValue.value;
                if (inputValue.unit !== undefined) {
                    this._value.unit = inputValue.unit;
                }
            }
        } else {
            this._value.value = inputValue;
            this._value.unit = null;
        }
    }
}

export default StyleShorthandBoxModelWithUnit;