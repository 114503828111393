import Style from './base';
import StyleCycle from './cycle';

class StyleColor extends StyleCycle {
    constructor(args) {
        super(args);
        this.the_class = 'colorModelWithUnit';
        if (VV?.colors) {
            this._cycle = VV.colors.colors().map(c=>c.color);
        } else {
            this._cycle = ['black', 'gray', 'white']; // List of predefined colors
        }
    }
}

export default StyleColor;