export default ({inline = false, date = new Date()} = {}) => ({
    MONTH_NAMES: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    DAYS: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    open: false,
    date: date,
    toggle() {
        this.open = ! this.open
    },
    inline: inline,
    showDatepicker: inline || false,
    datepickerValue: '',

    month: '',
    year: '',
    no_of_days: [],
    blankdays: [],
    days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

    initDate() {
        this.month = this.date.getMonth();
        this.year = this.date.getFullYear();
        this.datepickerValue = new Date(this.year, this.month, this.date.getDate()).toDateString();
    },

    isToday(date) {
        const today = new Date();
        const d = new Date(this.year, this.month, date);

        return today.toDateString() === d.toDateString() ? true : false;
    },
    isSelected(date) {
        const d = new Date(this.year, this.month, date);

        return this.datepickerValue === d.toDateString() ? true : false;
    },

    getDateValue(date) {
        let selectedDate = new Date(this.year, this.month, date);
        this.datepickerValue = selectedDate.toDateString();

        this.$refs.date.value = selectedDate.getFullYear() +"-"+ ('0'+ selectedDate.getMonth()).slice(-2) +"-"+ ('0' + selectedDate.getDate()).slice(-2);

        console.log(this.$refs.date.value);

        this.showDatepicker = this.inline || false;
    },

    getNoOfDays() {
        let daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

        // find where to start calendar day of week
        let dayOfWeek = new Date(this.year, this.month).getDay();
        let blankdaysArray = [];
        for ( var i=1; i <= dayOfWeek; i++) {
            blankdaysArray.push(i);
        }

        let daysArray = [];
        for ( var i=1; i <= daysInMonth; i++) {
            daysArray.push(i);
        }

        this.blankdays = blankdaysArray;
        this.no_of_days = daysArray;
    }

})