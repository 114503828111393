import Style from './base';
import StyleCycle from './cycle';

class StyleFontWeight extends StyleCycle {
    constructor(args) {
        super(args);
        this.the_class = 'fontWeightModelWithUnit';
        this._cycle = this.createRange(100,900,100);
        console.log('StyleFontWeight', this.cycle);
    }
}

export default StyleFontWeight;