import Style from './base';
import StyleWithUnit from './withUnit';

class StyleCycle extends StyleWithUnit {
    constructor(args) {
        super(args);
        this.the_class = 'cycleModelWithUnit';
        this.multiValueSeparator = /\s+/;
        this.defaultUnit = null;
        // this._cycleIndex = this.cycleCurrentIndex; // Start at the current index
        // this._cycleIndex = 0;
        this._cycle = [];
    }
    get cycle() {
        return this._cycle;
    }

    get cycleIndex() {
        return this._cycleIndex ?? 0;
    }

    get cycleCurrent() {
        return this.cycle[this.cycleIndex];
    }
    get cycleInput() {
        // where to get input to find cycle index? e.g. 
        return this.css;
    }

    get cycleCurrentIndex() {
        // console.log('cycleCurrentIndex', this.cycle, this.cycleInput);
        return this.cycle.indexOf(this.cycleInput);
    }

    get cycleCurrent() {
        return this.cycle[this.cycleCurrentIndex];
    }
    get cycleNext() {
        return this.cycle[(this.cycleCurrentIndex + 1) % this.cycle.length];
    }
    get cyclePrevious() {
        return this.cycle[(this.cycleCurrentIndex - 1 + this.cycle.length) % this.cycle.length];
    }
    
    increment() {
        this.value = this.cycleNext;
    }

    decrement() {
        this.value = this.cyclePrevious;
    }

}

export default StyleCycle;